import { useEffect, useMemo, useRef, useState } from 'react';

import { ModalWrapper } from '../../../ConfirmCancelModalContext/ModalWrapper';
import { CourseModalCard } from '../Components/CourseModalCard';
import {
  CourseMultiSelect,
  type CourseOption,
} from '../Components/CourseMultiSelect';

interface AddCoursesModalProps {
  onClose: () => void;
  onConfirm: (courseIds: string[]) => void;
  existingCourseIds: string[];
}

export function AddCoursesToStackModal({
  onClose,
  onConfirm,
  existingCourseIds,
}: AddCoursesModalProps) {
  const [selectedCourses, setSelectedCourses] = useState<CourseOption[]>([]);
  const coursesListRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (selectedCourses.length > 0) {
      const container = coursesListRef.current;
      if (container) {
        container.scrollTop = container.scrollHeight;
      }
    }
  }, [selectedCourses.length]);

  const handleConfirm = () => {
    if (selectedCourses.length > 0) {
      onConfirm(selectedCourses.map((course) => course.value));
    }
  };

  const handleDeleteCourse = (id: string) => {
    setSelectedCourses((prev) => prev.filter((c) => c.value !== id));
  };

  const excludedIds = useMemo(
    () => [...existingCourseIds, ...selectedCourses.map((c) => c.value)],
    [existingCourseIds, selectedCourses]
  );

  return (
    <ModalWrapper
      borderStyle='gray'
      containerClassName='w-[800px] h-auto'
      innerClassName='bg-main-layer'
      onClose={onClose}
    >
      <div className='px-8 pt-8 pb-8 text-white flex flex-col gap-6'>
        <div className='text-2xl font-bold'>Add Courses to Stack</div>

        {selectedCourses.length > 0 && (
          <div className='rounded-lg overflow-hidden'>
            <div
              ref={coursesListRef}
              className='max-h-64 overflow-y-auto flex flex-col gap-2.5'
            >
              {selectedCourses.map((course) => (
                <CourseModalCard
                  key={course.value}
                  onDelete={handleDeleteCourse}
                  course={course}
                />
              ))}
            </div>
          </div>
        )}

        <div>
          <div className='text-sms font-medium text-icon-gray mb-2'>
            Select courses to add:
          </div>
          <CourseMultiSelect
            onSelect={(course) => {
              setSelectedCourses((prev) => [...prev, course]);
            }}
            placeholder='Search and select courses'
            disabled={false}
            excludeCourseIds={excludedIds}
          />
        </div>

        <div className='flex justify-center gap-4'>
          <button
            type='button'
            onClick={onClose}
            className='px-6 py-2 rounded-lg transition-colors btn btn-secondary'
          >
            Cancel
          </button>
          <button
            type='button'
            onClick={handleConfirm}
            disabled={selectedCourses.length === 0}
            className={`px-6 py-2 btn btn-luna-primary ${
              selectedCourses.length === 0
                ? 'opacity-50 cursor-not-allowed'
                : ''
            }`}
          >
            Add
          </button>
        </div>
      </div>
    </ModalWrapper>
  );
}
